<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="11" class="white rounded-xl">
      <v-tabs :color="color.primaryVariant" v-model="tab">
        <v-tab>
          <span class="wc-caption-1">Eventos</span>
        </v-tab>
        <v-tab>
          <span class="wc-caption-1">Invitaciones</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Events></Events>
        </v-tab-item>

        <v-tab-item>
          <Invitations></Invitations>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { colors } from "@/constants/colors";
import { mapState } from "vuex";
import Events from "../memberEvents";
import Invitations from "./MemberInvitations";

export default {
  name: "MyEvents",
  components: {
    Events,
    Invitations
  },
  data() {
    return {
      color: colors,
      tab: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {},
};
</script>

<style>
</style>