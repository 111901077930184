<template>
  <v-container app>
    <v-row justify="center" align="center">
      <v-col cols="10" md="9">
        <v-sheet color="accent rounded-xl pl-3"
          ><v-text-field
            v-model="busqueda"
            solo
            dense
            placeholder="Buscar evento"
            hide-details="auto"
            class="rounded-search"
            clearable
          >
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" disabled>
                    <v-icon>fa fa-search</v-icon>
                  </v-btn>
                </template>
                Clic para buscar evento
              </v-tooltip>
            </template>
          </v-text-field>
        </v-sheet>
      </v-col>

      <v-col cols="2" v-if="$vuetify.breakpoint.smAndDown">
        <v-btn icon @click="toogleFilter()" class="mx-auto">
          <v-icon size="36" color="#ffb500"> mdi-filter </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp || showFilter">
        <v-sheet color="white" rounded="lg" class="pa-4" elevation="2">
          <!--Panel de filtros de busqueda-->
          <v-row class="my-2">
            <v-col>
              <span class="font-weight-bold">Origen del Curso</span>
              <div class="ml-3">
                <v-checkbox
                  v-model="filter.source"
                  color="#1b273e"
                  value="HEAD_OFFICE"
                  hide-details
                >
                  <template v-slot:label>
                    <span class="black--text">{{ headOfficeCompany }}</span>
                  </template>
                </v-checkbox>

                <v-checkbox
                  v-if="branchCompany"
                  v-model="filter.source"
                  color="#1b273e"
                  value="BRANCH"
                  hide-details
                >
                  <template v-slot:label>
                    <span class="black--text">{{ branchCompany }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="my-2" v-if="types.length > 0">
            <v-col>
              <span class="font-weight-bold">Tipo de Evento</span>
              <div class="ml-3">
                <v-checkbox
                  v-for="(type, index) in types"
                  :key="index"
                  color="#1b273e"
                  :value="type"
                  hide-details
                  v-model="filter.types"
                >
                  <template v-slot:label>
                    <span class="black--text">{{ type }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="my-2" v-if="tags.length > 0">
            <v-col>
              <span class="font-weight-bold">Etiqueta</span>
              <div class="ml-3">
                <v-checkbox
                  v-for="(tag, index) in tags"
                  :key="index"
                  color="#1b273e"
                  :value="tag"
                  hide-details
                  v-model="filter.tags"
                >
                  <template v-slot:label>
                    <span class="black--text">{{ tag }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="9">
        <v-row>
          <v-col
            v-for="event in filteredEvents"
            :key="event._id"
            cols="12"
            sm="6"
            md="4"
          >
            <EventCard :event="event" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventCard from "../../components/memberDashboard/events/eventCard.vue";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import Endpoints from "@/share/Endpoints";

export default {
  components: {
    EventCard,
  },
  data() {
    return {
      urel: "",
      busqueda: "",
      events: [],
      categories: [],
      tags: [],
      types: [],
      headOfficeCompany: "",
      branchCompany: "",

      //Filtros
      filter: {
        source: [],
        types: [],
        categories: [],
        tags: [],
      },

      showFilter: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
    filteredEvents() {
      const eventosFiltrados = [];

      for (const evento of this.events) {
        const source =
          evento.organization == this.user.headOffice._id
            ? "HEAD_OFFICE"
            : "BRANCH";
        // Comprobamos todas las condiciones para determinar si el evento pasa el filtro
        const pasaFiltro =
          (this.filter.source.length === 0 ||
            this.filter.source.includes(source)) &&
          // Filtrar por types (si está vacío, o es igual a '', no aplicamos filtro)
          (this.filter.types.length === 0 ||
            this.filter.types.includes(evento.type)) &&
          // Filtrar por categories (si está vacío, o es igual a '', no aplicamos filtro)
          (this.filter.categories.length === 0 ||
            this.filter.categories.includes(evento.category)) &&
          // Filtrar por tags (si está vacío, o es igual a '', no aplicamos filtro)
          (this.filter.tags.length === 0 ||
            this.filter.tags.some((tag) => evento.tags.includes(tag))) &&
          // Filtrar por busqueda (si está vacío, no aplicamos filtro)
          (this.busqueda === "" ||
            evento.name.toLowerCase().includes(this.busqueda.toLowerCase()));

        // Si el evento pasa el filtro, lo agregamos a la lista de eventos filtrados
        if (pasaFiltro) {
          eventosFiltrados.push(evento);
        }
      }

      return eventosFiltrados;
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),

    ...mapActions("memberEvents", ["getEvents"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("notification", ["show"]),

    toogleFilter() {
      this.showFilter = !this.showFilter;
    },

    async getFilters() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `events/filters/${this.urel}`,
        });

        if (response.status == 200) {
          this.categories = response.data.data.categories;
          this.tags = response.data.data.tags;
          this.types = response.data.data.types;
          this.headOfficeCompany = response.data.data.headOffice;
          this.branchCompany = response.data.data.branch;
        } else {
          this.show({
            text: "Hubo un problema al obtener los Filtros",
            color: "error",
          });
        }
      } catch (error) {
        this.show({
          text: "Hubo un problema al obtener los Filtros",
          color: "error",
        });
      }
    },
  },
  async created() {
    

    if (this.user.role == "MEMBERSHIP_USER" ||this.user.role == "MEMBER") {
      this.urel = this.user.headOffice.id;
    } else {
      this.urel = this.userMembership.organization.id;
    }
    this.loading();

    try {
      this.loading();

      await this.getFilters();
      const events = await this.getEvents(this.urel);

      if (events.status) {
        this.events = events.events.data.events;
      } else {
        console.log(events.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<style>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.event-container {
  min-height: 93vh;
}
</style>
