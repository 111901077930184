<!--Tarjeta de evento que se muestra en el dashboard de organización-->
<template>
  <v-hover v-slot="{ hover }">
    <!--:class="!event.published?'grayscale-disabled-element':''"-->
    <v-card class="mx-auto event-card" rounded="xl" max-width="344" :elevation="hover ? '4' : '0'"
      :to="`/member/eventMicrosite/${event._id}`">
      <v-img v-if="event.banner && event.banner != ''" :src="event.banner"
        :gradient="hover ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)' : ''" :height="180"
        @click="editEvent()">
      </v-img>
      <v-img v-else src="@/assets/images/shared/withoutbanner.png"
        :gradient="hover ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)' : ''" :height="180"
        @click="editEvent()">
      </v-img>
      <v-card-title class="title font-weight-black pt-1 justify-center" style="max-width: 100%;" @click="editEvent()">
        <span class="text-truncate">{{ event.name }}</span>
      </v-card-title>

      <v-card-subtitle>
        <v-row dense>
          <v-col cols="12" @click="editEvent()">
            <p class="mb-0 subtitle-1 font-weight-bold text-center">
              {{ formatDate(event.dateConfiguration.startDate) }}
            </p>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </v-hover>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  props: {
    event: { type: Object, required: true }
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    editEvent() {
      console.log("editEvent");
    },
    /**
     * Función que devuelve un link para compartir el evento seleccionado.
     * @param { string } id Identificador único de un evento.
     */
    shareItem(id) {
      return `${window.location.origin}/micrositio/${id}`;
    },
    formatDate(date) {
      let [year, month, day] = date.substring(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    isValidDate(date) {
      let today = new Date();
      let currentDate = new Date(date);
      if (today > currentDate) {
        return false;
      } else {
        return true;
      }
    },
    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url del evento al portapapeles!",
        color: "primary"
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url del evento!",
        color: "error"
      });
    }
  }
}
</script>
<style scoped>
.event-card {
  transition: all .8s;
}

.event-card:hover {
  transform: scale(1.05);
}

.grayscale-disabled-element {
  filter: grayscale(1);
}
</style>