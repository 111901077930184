<template>
  <v-container app>

    <v-row justify="start" class="mx-0 my-2">
      <v-col cols="12" md="9">
        <v-row>
          <v-col
            v-for="event in events"
            :key="event._id"
            cols="12"
            sm="6"
            md="4"
          >
            <EventCard :event="event" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventCard from "@/components/memberDashboard/events/eventCard.vue";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import Endpoints from "@/share/Endpoints";

export default {
  components: {
    EventCard,
  },
  data() {
    return {
      events: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    async getEvents() {
      const response = await this.axios({
        method: "get",
        url: '/events/invited',
      })

      console.log(response.data);
      if (response.status) {
        this.events = response.data.events;
      }
    },
  },
  async created() {
    try {
      this.loading();
      await this.getEvents();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<style>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}
.event-container {
  min-height: 93vh;
}
</style>